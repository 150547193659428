import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20221025-Rate-Sheet.pdf";
const date = "25.10.2022";

const Article = () => (    
    <NewsArticleLayout href={href} date={date} isNew={isNew}>
     
            <h1 className="page-title">Changes to our mortgage products</h1>

            <p>With effect from Tuesday 25 October 2022 we will be making the following changes to our range</p>
            <p><strong>Summary of changes</strong></p>

            <p><strong>Residential Existing Customer Switching Range</strong></p>
            <ul>
                <li>2 Year Tracker at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
            </ul>

            <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
            <ul>
                <li>2 Year Tracker at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
            </ul>

            <p><strong>UK Residential First Time Buyer Range</strong></p>
            <ul>
                <li>2 Year Tracker at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
            </ul>

            <p><strong>UK Residential Remortgage Range</strong></p>
            <ul>
                <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
                <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
                <li>5 Year Fixed Premier Exclusive at 60%, 70% and 75% LTV have reduced</li>
                <li>2 Year Tracker at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
            </ul>

            <p><strong>UK Residential Home Mover Range</strong></p>
            <ul>
                <li>2 Year Tracker at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
            </ul>

            <p><strong>International Residential Range</strong></p>
            <ul>
                <li>2 Year Tracker at 60%, 70% and 75% LTV have reduced</li>
            </ul>

            <p>There are no changes to any of our fixed rate end dates or any of our other interest rates.</p>
            <p>We have introduced &pound;250 cashback on our 85% and 90% UK Residential First Time Buyer and Home Mover Ranges on a selection of 2 Year Tracker products.</p>
            <p>An updated mortgage rate sheet will be published on Tuesday 25 October to reflect these changes.</p>

    </NewsArticleLayout>
);

export default Article;

